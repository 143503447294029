<template>
    <div class="look-inside">
        <b-container class="look-inside" v-if="slides" fluid="xl">            
            <b-row v-if="showTitle">
                <b-col cols="12">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('take_look_inside_title')"></h2> 
                </b-col>
            </b-row>
            <b-row class="d-none d-md-flex">
                <b-col cols="12" md="6">                           
                    <agile 
                        class="mainCarousel animateScroll" 
                        ref="projectMain" 
                        :options="optionsMain" 
                        :as-nav-for="asNavFor1"
                        v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-from-left')"
                    >
                        <div class="slide" v-for="(slide, sindex) in slides" :key="sindex">                            
                            <img :src="slide" class="w-100" />                            
                            <button type="button" class="btn btn--zoom d-none d-md-block" @click="galleryIndex = sindex"></button>
                        </div>
                    </agile>                    
                    
                </b-col>                
                <b-col cols="12" md="6" class="thumbCarousel-wrap">
                    <agile class="thumbnailsCarousel animateScroll" 
                        ref="projectThumbnails" 
                        :options="optionsThumbs" 
                        :as-nav-for="asNavFor2"
                        @after-change="isActiveIndex=$refs.projectThumbnails.getCurrentSlide()"                        
                        :initialSlide="1"
                        v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-from-right')"
                    >
                        <div class="slide slide--thumbniail" 
                            v-for="(slide, sindex) in slides" :key="sindex" 
                            :class="[`slide--${sindex}`, {'hide_agile_div': sindex == isActiveIndex}]" 
                            @click="$refs.projectThumbnails.goTo(sindex)"
                        >                                                 
                            <img :src="slide" class="img-fluid"  />
                        </div>                                              
                    </agile>  
                    <div class="mainCarousel__buttons animateScroll" :class="[selectedLanguage === 'hu' ? 'mainCarousel__buttons--hu' : '',  selectedLanguage === 'en' ? 'mainCarousel__buttons--en' : '']" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-down', 'delay-1')">                            
                        <button type="button" @click="$refs.projectThumbnails.goToPrev()" class="btn btn--slider-left"></button>
                        <button type="button" @click="$refs.projectThumbnails.goToNext()" class="btn btn--slider-right"></button>
                    </div>                    
                </b-col>
            </b-row>
            <vue-gallery-slideshow :images="slidesBig" :index="galleryIndex" @close="galleryIndex = null"></vue-gallery-slideshow> 
            <!-- MOBILE GALLERY -->
            <b-row class="d-md-none">
                <b-col cols="12">
                        <OwlCarousel  :items="1" :margin="0" :dots="true"  :loop="false" :nav="false">
                                <div v-for="(slide, sindex) in slides" :key="sindex">              
                                    <div class="img-wrap">
                                        <img :src="slide" class="img-fluid"  />
                                    </div>
                                </div> 
                        </OwlCarousel>
                </b-col>
            </b-row>
        </b-container>   
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import VueGallerySlideshow from 'vue-gallery-slideshow';
import OwlCarousel from 'vue-owl-carousel'

export default {
    props:['flatslides', 'flatslidesBig', 'showTitle'],
    data(){
        return{
            slides: [],
            slidesBig:[],
            asNavFor1: [],
			asNavFor2: [], 
            isActiveIndex:1,           
            optionsMain:{
                dots: false,
				fade: true,
				navButtons: false,
                changeDelay: 1000,
                speed:800,
            },
            optionsThumbs:{
                // autoplay: true,
				centerMode: false,
				dots: false,
				navButtons: false,				
                slidesToShow: 2,    
                timing: 'ease-in-out',
                speed:800,
                changeDelay: 1000,
                initialSlide:0		
            },         
            galleryIndex: null,
            commonPictures: [
                "/pictures/flats_interior_gallery/1.jpg",
                "/pictures/flats_interior_gallery/2.jpg",
                "/pictures/flats_interior_gallery/3.jpg",
                "/pictures/flats_interior_gallery/4.jpg",
                "/pictures/flats_interior_gallery/5.jpg",
                "/pictures/flats_interior_gallery/6.jpg",
                "/pictures/flats_interior_gallery/7.jpg"
            ] ,
            commonPicturesBig: [
                "/pictures/flats_interior_gallery/1-Large.jpg",
                "/pictures/flats_interior_gallery/2-Large.jpg",
                "/pictures/flats_interior_gallery/3-Large.jpg",
                "/pictures/flats_interior_gallery/4-Large.jpg",
                "/pictures/flats_interior_gallery/5-Large.jpg",
                "/pictures/flats_interior_gallery/6-Large.jpg",
                "/pictures/flats_interior_gallery/7-Large.jpg"
            ] 
        }
    },
    components:{
        agile: VueAgile,
        VueGallerySlideshow,
        OwlCarousel
    },
    mounted(){
        this.asNavFor1.push(this.$refs.projectThumbnails)
        this.asNavFor2.push(this.$refs.projectMain)
    },
    created(){
        let _this = this

        if (!this.flatslides && !this.flatslidesBig){
            _this.slides = _this.commonPictures
            _this.slidesBig = _this.commonPicturesBig
        } else if(this.flatslidesBig.length === 0) {
            _this.slides = _this.flatslides
            _this.slidesBig = _this.flatslides
        } else{
            _this.slides = _this.flatslides
            _this.slidesBig = _this.flatslidesBig
        }
        
    }
}
</script>